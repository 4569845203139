<template>
  <div :class="animation">
    <b-card
      title="Nouvelle adresse"
      title-tag="h5"
    >
      <b-row>
        <b-col sm="6">
          <b-form-group label="Moyen de change">
            <b-form-select
              v-model="$v.tarifId.$model"
              :class="{
                'is-invalid': $v.tarifId.$error,
              }"
              :options="tarifsOptions"
            />
            <div
              v-if="$v.tarifId.$error"
              class="invalid-feedback"
            >
              <span v-if="!$v.tarifId.required">{{
                $t("errors.required")
              }}</span>
            </div>
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group label="Dénomination">
            <b-form-input
              v-model="$v.assocName.$model"
              :formatter="formatter"
              type="text"
              :class="{
                'is-invalid': $v.assocName.$error,
              }"
            />
            <div
              v-if="$v.assocName.$error"
              class="invalid-feedback"
            >
              <span v-if="!$v.assocName.required">{{
                $t("errors.required")
              }}</span>
            </div>
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group label="Solde disponible">
            <b-form-input
              v-model.number="$v.value.$model"
              type="number"
              :class="{
                'is-invalid': $v.value.$error,
              }"
            />
            <div
              v-if="$v.value.$error"
              class="invalid-feedback"
            >
              <span v-if="!$v.value.required">{{
                $t("errors.required")
              }}</span>
              <span v-if="!$v.value.numeric">{{
                $t("errors.required")
              }}</span>
            </div>
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group label="Adresse de paiment">
            <b-form-input
              v-model="$v.address.$model"
              type="text"
              :class="{
                'is-invalid': $v.address.$error,
              }"
            />
            <div
              v-if="$v.address.$error"
              class="invalid-feedback"
            >
              <span v-if="!$v.address.required">{{
                $t("errors.required")
              }}</span>
            </div>
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group label="Appliqué ?">
            <b-form-checkbox
              v-model="$v.state.$model"
              :value="true"
              :unchecked-value="false"
            >
              <span>{{ state ? 'Oui' : 'Non' }}</span>
            </b-form-checkbox>
            <div
              v-if="$v.state.$error"
              class="invalid-feedback"
            >
              <span v-if="!$v.state.required">{{
                $t("errors.required")
              }}</span>
            </div>
          </b-form-group>
        </b-col>
        <b-col
          sm="12"
        >
          <div class="text-center">
            <b-spinner
              v-if="process"
              variant="primary"
            />
          </div>
          <div class="text-right mt-2">
            <b-button
              variant="outline-primary"
              :disabled="process"
              @click="tryToSave"
            >
              <span>{{ $t("form.save") }}</span>
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { required, numeric } from 'vuelidate/lib/validators'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  data() {
    return {
      animation: null,
      assocName: null,
      tarifId: null,
      state: false,
      value: null,
      address: null,
      allCurrencies: [],
    }
  },
  validations: {
    assocName: {
      required,
    },
    tarifId: {
      required,
    },
    state: {
      required,
    },
    value: {
      required,
      numeric,
    },
    address: {
      required,
    },
  },
  computed: {
    ...mapGetters({
      success: 'newAddressSuccess',
      error: 'newAddressError',
      process: 'newAddressProcess',
    }),
    tarifsOptions() {
      const tarifs = this.allCurrencies
      const data = [
        { value: null, text: 'Chosir', disabled: true },
      ]
      if (tarifs.length > 0) {
        // eslint-disable-next-line array-callback-return
        tarifs.map(item => {
          let current
          if (!item.is_auto && !item.is_crypto && item.dev_code !== 'VISA') {
            current = {
              value: item.id,
              text: item.libelle,
            }
            data.push(current)
          }
        })
      }
      return data
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        this.getAllCurrencies()
      },
    },
    success(val) {
      if (val) {
        this.reset()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('address.title'),
            text: this.$t('address.success'),
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })
        this.$router.push({ name: 'admin-settings-all-address' })
      }
    },
    error(val) {
      if (val) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('address.title'),
            text: this.$t('address.error'),
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      }
    },
  },
  methods: {
    ...mapActions(['newAddress']),
    formatter(value) {
      return value.toUpperCase()
    },
    reset() {
      this.assocName = null
      this.tarifId = null
      this.value = null
      this.state = null
      this.address = null
      this.$nextTick(() => {
        this.$v.$reset()
      })
    },
    async getAllCurrencies() {
      this.allCurrencies = await
      this.$http
        .get('/currencies/all', {
          headers: {
            Authorization: `Bearer ${this.$store.getters.currentToken}`,
          },
        })
        .then(res => res.data.data)
        .catch(() => [])
    },

    tryToSave() {
      this.$v.$touch()
      if (this.$v.$anyError) {
        this.animation = 'animate-wrong'
        setTimeout(() => {
          this.animation = null
        }, 1000)
        return
      }
      this.newAddress({
        assocName: this.assocName,
        tarifId: this.tarifId,
        state: this.state,
        value: this.value,
        address: this.address,
      })
    },
  },
}
</script>
<style>
.animate-wrong {
  animation: wrong 0.4s ease-in-out;
}

@keyframes wrong {
  0% {
    transform: translateX(0);
  }
  20% {
    transform: translateX(40px);
  }
  40% {
    transform: translateX(20px);
  }
  60% {
    transform: translateX(40px);
  }
  80% {
    transform: translateX(20px);
  }
  100% {
    transform: translateX(0);
  }
}
</style>
